<template>
    <select v-model="currentLocation" data-test="location-switcher" @change="updateLocation()">
        <option
            v-for="(loc, index) of locationsText"
            :key="index"
            :value="loc"
        >
            &gt; {{ loc }}
        </option>
    </select>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "LocationSwitcher",
    data() {
        return {
            currentLocation: undefined,
        };
    },
    computed: {
        ...mapState(["defaultLocation", "locations"]),

        locationsText() {
            return this.locations.map((l) => l?.text);
        },
    },
    created() {
        this.currentLocation = this.defaultLocation?.text;
    },
    methods: {
        ...mapActions(["changeLocation", "clearStore", "getAnalytics", "getReport", "getConfig", "getFeedback"]),

        async updateLocation() {
            const newLocation = this.locations.find((l) => l?.text === this.currentLocation);
            this.changeLocation({ location: newLocation });

            this.clearStore();

            switch (this.$route.name) {
                case "dashboard":
                case "weeklyUtilisation":
                    this.getAnalytics();
                    break;
                case "surveyResponse":
                    this.getReport({ data: ["surveyReport"] });
                    break;
                case "lockers":
                    this.getFeedback();
                    break;
                case "AgeVerification":
                    this.getReport({ data: ["ageVerification"] });
                    break;
                case "Orders":
                    this.getReport({ data: ["orderReport"] });
                    break;
                case "settings":
                    this.getConfig();
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
select {
    border: 2px solid var(--colors-primary);
    border-radius: 6px;
    background-color: var(--colors-accent-2);
    padding:  2px 10px 2px 4px;
    margin: 2px 0 4px;
}
</style>
